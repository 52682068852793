import 'lazysizes';
import '/src/css/app.css';
import Alpine from 'alpinejs'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

// htmx
if (typeof htmx !== 'undefined') {
    // scroll to top of page after htmx swap
    htmx.on('htmx:afterSwap', function(event) {
        /* console.log('htmx parameters', event.detail.requestConfig.parameters) */

        const scrollTop = event.detail?.requestConfig?.parameters?.scrollTop || false;
        if (scrollTop > 0 ) { /* scroll to #sprig-container, taking into account header height */
            const sprigContainer = document.getElementById('sprig-container');
            const header = document.getElementById('header');

            if (sprigContainer && header) {
                const scrollY = sprigContainer.getBoundingClientRect().top + window.scrollY - header.offsetHeight;
                window.scrollTo({top: scrollY, behavior: 'smooth'});
            }
        }
    });
}

window.Alpine = Alpine;
Alpine.start()

